.favorite-success-popup {
  position: fixed;
  top: 20px;
  left: 10px;
  color: white;
  padding: 10px 10px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  animation: slideRight 0.3s ease-out;
  backdrop-filter: blur(5px);
  width: 40%;
}

.favorite-success-popup.favorite {
  background-color: rgba(46, 125, 50, 0.95);
}

.favorite-success-popup.unfavorite {
  background-color: rgba(211, 47, 47, 0.95);
}

.favorite-success-content {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 12px;
}

@keyframes slideRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.favorite-success-popup.closing {
  animation: slideLeft 0.3s ease-in forwards;
} 