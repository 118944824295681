.admin-shipments-container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.admin-shipments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-shipments-header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-shipments-back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.admin-shipments-back-button:hover {
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.admin-shipments-controls {
  margin-bottom: 20px;
}

.admin-shipments-search {
  margin-bottom: 15px;
}

.admin-shipments-search-container {
  position: relative;
  max-width: 400px;
}

.admin-shipments-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

.admin-shipments-search-input {
  width: 100%;
  padding: 10px 40px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 14px;
}

.admin-shipments-search-input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.admin-shipments-clear-search-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  padding: 0;
}

.admin-shipments-filters {
  display: flex;
  gap: 20px;
}

.admin-shipments-filter-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.admin-shipments-filter-select {
  padding: 6px 12px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  color: #495057;
}

.admin-shipments-table-container {
  overflow-x: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.admin-shipments-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 1000px;
}

.admin-shipments-table th,
.admin-shipments-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.admin-shipments-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
}

.admin-shipments-status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

.admin-shipments-status-badge.pending {
  background-color: #fff3cd;
  color: #856404;
}

.admin-shipments-status-badge.shipped {
  background-color: #cce5ff;
  color: #004085;
}

.admin-shipments-status-badge.delivered {
  background-color: #d4edda;
  color: #155724;
}

.admin-shipments-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding: 20px 0;
}

.admin-shipments-pagination-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.admin-shipments-pagination-button:hover:not(:disabled) {
  background-color: #e9ecef;
}

.admin-shipments-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.admin-shipments-pagination-info {
  font-size: 14px;
  color: #6c757d;
}

.admin-shipments-loading,
.admin-shipments-error {
  text-align: center;
  padding: 40px;
  font-size: 1.2em;
  color: #6c757d;
}

.admin-shipments-error {
  color: #dc3545;
}

.admin-shipments-tracking-link {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s;
}

.admin-shipments-tracking-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.admin-shipments-header-buttons {
  display: flex;
  gap: 10px;
}

.admin-shipments-export-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.admin-shipments-export-button:hover:not(:disabled) {
  background-color: #218838;
}

.admin-shipments-export-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.admin-shipments-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
} 