.self-collection-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.self-collection-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 85%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.self-collection-calendar {
  margin: 20px 0;
}

.date-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.time-slots {
  margin: 20px 0;
}

.time-slots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

.time-slot {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.time-slot:hover {
  background: #f5f5f5;
}

.time-slot.selected {
  background: #007bff;
  color: white;
  border-color: #0056b3;
}

.self-collection-selected-items {
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.self-collection-selected-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.self-collection-selected-item:last-child {
  border-bottom: none;
}

.self-collection-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.self-collection-cancel-button, .self-collection-confirm-button {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.self-collection-cancel-button {
  background: #f8f9fa;
  color: #212529;
}

.self-collection-confirm-button {
  background: #007bff;
  color: white;
}

.self-collection-confirm-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.self-collection-error-message {
  color: red;
  margin: 10px 0;
}

.self-collection-items-title {
    margin-bottom: 5px;
    margin-top: 5px;
}

.collection-info {
    margin-bottom: 5px;
    margin-top: 5px;
    color: #666;
}

.self-collection-select-date-title {
    margin-bottom: 10px;
    margin-top: 5px;
}