.trade-history {
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.trade-history h1 {
  text-align: center;
  font-family: 'Bungee', sans-serif;
}

.trade-history-card {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
  width: 88%;
  margin-left: auto;
  margin-right: auto;
}

.trade-history-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
}

.trade-history-date {
  color: #666;
  font-size: 0.9em;
}

.trade-history-status {
  color: green;
  font-size: 0.9em;
}

.trade-history-details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.trade-history-section {
  flex: 1;
  width: 100%;
}

.trade-history-section h3 {
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 1em;
  text-align: left;
}

.trade-history-prizes {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  width: 100%;
  padding-bottom: 10px;
}

.trade-history-prize-card {
  flex: 0 0 auto;
  width: 120px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}

.trade-history-prize-card img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}

.trade-history-prize-card h4 {
  font-size: 14px;
  margin: 5px 0;
}

.trade-history-prize-card p {
  font-size: 12px;
  margin: 0;
  margin-bottom: 5px;
}

.trade-history-partner {
  margin-top: 15px;
  text-align: right;
  color: #666;
  font-style: italic;
}

.no-trades-message {
  text-align: center;
  color: #666;
  padding: 20px;
} 