.inventory-shipping-info-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    text-align: center;
}
  
.inventory-shipping-info-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
  
.inventory-shipping-info-popup h3 {
    color: #333;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.5rem;
}
  
.inventory-shipping-info-popup p {
    margin-bottom: 10px;
    color: #666;
    font-size: 1.1rem;
}
  
.inventory-shipping-info-popup ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
}
  
.inventory-shipping-info-popup li {
    color: #444;
    margin: 8px 0;
    font-size: 1.1rem;
    padding: 8px;
    background: #f5f5f5;
    border-radius: 5px;
}
  
.inventory-shipping-info-popup-close-button {
    background-color: #ff7e5f;
    color: white;
    border: none;
    padding: 10px 25px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
    transition: background-color 0.2s;
}
  
.inventory-shipping-info-popup-close-button:hover {
    background-color: #ff5733;
}