/* Inventory Container */
.inventory-container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 30px;
  min-height: 60vh;
}

.inventory-title {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-family: "BUNGEE";
  margin-top: 0px;
}

/* Set Section */
.inventory-set {
  margin-bottom: 0px;
  text-align: left;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.inventory-set-name {
  font-size: 1.3rem;
  color: #ff7e5f;
  text-align: left;
  margin-bottom: 5px;
}

/* Prizes Container */
.inventory-prizes-container {
  display: flex;
  gap: 10px; /* Space between cards */
  width: 100%; /* Ensure container fits within parent */
  box-sizing: border-box;
  margin-top: 10px;
}

/* Scrollable Wrapper */
.inventory-prize-scroll-wrapper {
  display: flex;
  overflow-x: auto;
  padding: 10px 0; /* Add some padding for better spacing */
  gap: 10px; /* Space between prize cards */
  scroll-behavior: smooth; /* Smooth scrolling effect */
  width: 100%;
}

.inventory-prize-scroll-wrapper::-webkit-scrollbar {
  height: 8px; /* Horizontal scrollbar height */
}

.inventory-prize-scroll-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Scrollbar color */
  border-radius: 4px; /* Rounded scrollbar */
}

.inventory-prize-scroll-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #999; /* Scrollbar hover color */
}

/* Prize Card */
.inventory-prize-card {
  flex: 0 0 auto;
  width: 30%;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  overflow: hidden;
  position: relative; /* Added for prize type indicator */
}

/* Add new prize type styles */
.inventory-prize-card.big-prize {
  border: 3px solid #ffd700; /* Gold border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 10px rgba(255, 215, 0, 0.3);
}

.inventory-prize-card.small-prize {
  border: 2px solid #c0c0c0; /* Silver border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 10px rgba(192, 192, 192, 0.3);
}

/* Update the inventory prize type indicator styles */
.inventory-prize-type-indicator {
  position: absolute;
  top: 5px;  /* Move to very top */
  right: -65px;  /* Move more to the right */
  padding: 5px 30px;  /* Wider padding for the banner look */
  font-size: 0.6rem;  /* Slightly smaller font */
  font-weight: bold;
  z-index: 1;
  transform: rotate(45deg);  /* Rotate 45 degrees */
  transform-origin: center;  /* Rotate from center */
  width: 100px;  /* Fixed width for consistent look */
  text-align: center;
}

.inventory-prize-type-indicator.big {
  background-color: #ffd700;
  color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inventory-prize-type-indicator.small {
  background-color: #c0c0c0;
  color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Hover effects remain the same for both types */
.inventory-prize-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.inventory-prize-name {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 5px;
  margin-top: 0px;
}

.inventory-prize-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 5px;
}

/* Batches */
.inventory-batches-container {
  margin-top: 5px;
}

.inventory-batch-details {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 5px;
}

.inventory-batch-details span {
  display: block;
}

.inventory-ship-by {
  margin-top: 5px;
  font-size: 0.7rem;
}

/* No Inventory Message */
.no-inventory-message {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  margin-top: 20px;
}

.inventory-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.inventory-action-button {
  padding: 10px 15px;
  border: none;
  background-color: #f1f1f1;
  color: #333;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s;
  width: 30%;
}

.inventory-action-button.active {
  background-color: #ff7e5f;
  color: white;
}

.inventory-action-button:hover:not(.active) {
  background-color: #ddd;
}

.inventory-quantity-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.inventory-quantity-btn {
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  padding: 0px 10px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
}

.inventory-quantity-input {
  width: 60px; /* Adjust width to ensure it fits */
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 3px;
  box-sizing: border-box;
}

.inventory-quantity-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.inventory-quantity-input:disabled {
  background-color: #f9f9f9;
  cursor: not-allowed;
}

.inventory-batch-disabled-message {
  font-size: 0.8rem;
  color: #888;
  margin-top: 5px;
}

.inventory-confirm-action-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff7e5f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.inventory-confirm-action-button:hover {
  background-color: #ff5733;
}

.inventory-floating-confirm-button {
  position: fixed;
  bottom: 20px; /* Distance from the bottom of the screen */
  right: 20px; /* Distance from the right of the screen */
  z-index: 99; /* Ensure it stays on top of other elements */
  background-color: #ff7e5f;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease-in-out;
}

.inventory-floating-confirm-button:hover {
  background-color: #ff5733;
}

.inventory-floating-confirm-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}


/* Modal Overlay */
.inventory-confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* Make sure it stays above other elements */
}

/* Modal Content */
.inventory-confirm-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 82%; /* Mobile-friendly width */
  max-width: 400px; /* Maximum width */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative; /* For positioning the close button */
  animation: fadeIn 0.3s ease-in-out; /* Fade-in effect */
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Modal Header */
.inventory-confirm-modal-content h4 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  margin-top: 0px;
}

/* Modal List */
.inventory-confirm-modal-content ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.inventory-confirm-modal-content ul li {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 5px;
  text-align: left;
}

/* Modal Buttons */
.inventory-confirm-modal-content .inventory-confirm-button {
  background-color: #ff7e5f;
  color: white;
  margin-top: 15px;
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.inventory-confirm-modal-content .inventory-confirm-button:hover {
  background-color: #ff5733;
}

.inventory-confirm-modal-content .inventory-cancel-button {
  background-color: #ddd;
  color: #333;
  margin-top: 15px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.inventory-confirm-modal-content .inventory-cancel-button:hover {
  background-color: #ccc;
}

.inventory-confirm-total-items {
  margin-top: 15px;
  font-size: 1rem;
  color: #444;
  text-align: center;
  font-weight: bold;
}

.inventory-arrival {
  text-align: left; /* Align text to the left */
  font-size: 0.9rem; /* Optional: Adjust font size */
  color: #555; /* Optional: Adjust text color */
  display: block;
  font-weight: bold;
}

.inventory-search {
  display: flex;
  align-items: center;
  margin-top: 10px;  
}

.inventory-search-input {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inventory-status-filter {
  margin: 1rem 0;
  display: flex;
  justify-content: end;
}

.inventory-status-filter-select {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.inventory-status-filter-select:hover {
  background: rgba(255, 255, 255, 0.2);
}

.inventory-status-filter-select option {
  background: #2c2c2c;
  color: white;
}

.inventory-section-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  margin-top: 5px;
  text-align: left;
}

.inventory-arrival {
  font-size: 0.9rem;
}

/* Add a subtle visual difference between instock and preorder items */
.inventory-section:nth-child(2) .inventory-set {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
}
