.view-offers {
    width: 90%;
    margin: 20px auto;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
  
  h1 {
    text-align: center;
    color: #333;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .offer-card {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .offer-by {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .offer-prizes {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping */
    gap: 10px; /* Space between cards */
    overflow-x: auto; /* Enable horizontal scrolling */
    padding-bottom: 10px; /* Add some spacing below */
  }
  
  .offer-prizes::-webkit-scrollbar {
    height: 8px; /* Height of horizontal scrollbar */
  }
  
  .offer-prizes::-webkit-scrollbar-thumb {
    background: grey; /* Thumb color */
    border-radius: 4px;
  }
  
  .offer-prizes::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
  }
  
  .offer-prize-card {
    flex: 0 0 auto; /* Prevent shrinking and keep size */
    width: 40%; /* Set a fixed width for cards */
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 5px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .offer-prize-card-name {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.9em;
  }

  .offer-prize-card-description {
    margin-top: 3px;
    font-size: 0.8em;
  }

  .offer-prize-card-qty {
    margin-bottom: 3px;
    font-size: 0.8em;
  }

  .offer-prize-card-set-name {
    margin-bottom: 3px;
    font-size: 0.8em;
  }
  
  .offer-prize-image {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  
  .offer-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .accept-offer-button {
    background: #25AF19; /* The button's background color */
    color: white; /* The text color */
    font-size: 1em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 10px; /* Rounded corners */
    padding: 5px 0px;
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 40%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
  }
  
  .reject-offer-button {
    color: white; /* The text color */
    font-size: 1em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 10px; /* Rounded corners */
    padding: 5px 0px;
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 40%;
    font-family: 'Bungee';
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0 6px #800020; /* Shadow effect to make the button look clickable */
    background-color: #DE3163;
  }
  
  .accept-offer-button:hover {
    background-color: #45a049;
  }
  
  .reject-offer-button:hover {
    background-color: #e53935;
  }  

  .offer-user-profile-picture {
    width: 30px; /* Adjust size */
    height: 30px; /* Adjust size */
    border-radius: 50%; /* Make it circular */
    object-fit: cover; /* Maintain aspect ratio */
    vertical-align: middle; /* Align with the text */
    border: 2px solid #ddd; /* Optional: Add a border for styling */
    margin: 0px 5px;
  }

  .offer-confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .offer-confirmation-popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .offer-confirmation-popup-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
  
  .offer-confirm-action-button,
  .offer-cancel-action-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    width: 35%;
  }
  
  .offer-confirm-action-button {
    background-color: #28a745;
    color: white;
  }
  
  .offer-cancel-action-button {
    background-color: #dc3545;
    color: white;
  }
  
  