/* Modal Container */
.inventory-selection-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .inventory-selection-modal-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
    overflow-y: auto;
    height: 85vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .inventory-selection-modal-content h4 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
  }
  
  /* Inventory Container */
  .inventory-selection-container {
    flex: 1;
    overflow-y: auto;
    padding: 0 5px;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  
  /* Set Section */
  .inventory-selection-set {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .inventory-selection-set h5 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #444;
    text-align: center;
  }
  
  /* Prizes Container */
  .inventory-selection-prizes {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    width: 100%;
  }
  
  /* Prize Card */
  .inventory-selection-prize-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 0.9em;
    color: #555;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    width: 40%;
  }
  
  .inventory-selection-prize-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .inventory-selection-prize-card img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  /* Batch Details */
  .inventory-selection-batch-details {
    font-size: 0.85em;
    color: #666;
    margin-top: 5px;
    width: 100%;
  }
  
  .inventory-selection-batch-details span {
    display: block;
    margin-bottom: 5px;
  }
  
  .inventory-selection-quantity-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
  }
  
  .inventory-selection-quantity-controls button {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.2s ease;
    width: 25%;
  }
  
  .inventory-selection-quantity-controls button:hover {
    background-color: #0056b3;
  }
  
  .inventory-selection-quantity-controls span {
    width: 30px;
    text-align: center;
    margin: 0 8px;
    font-weight: bold;
    color: #333;
  }

  .inventory-selection-quantity-input {
    width: 30%;
    text-align: center;
    margin: 0px 5px;
  }
  
  /* Modal Actions */
  .inventory-selection-modal-buttons {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 15px;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    z-index: 1000;
  }
  
  .inventory-selection-modal-buttons button {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .inventory-selection-modal-buttons button:hover {
    background-color: #0056b3;
  }
  
  .inventory-selection-modal-buttons button:first-child {
    background-color: #6c757d;
  }
  
  .inventory-selection-modal-buttons button:first-child:hover {
    background-color: #5a6268;
  }
  
  /* Animation for modal */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  .inventory-selection-prize-name {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .inventory-selection-search {
    width: 90%;
    margin: 0px auto;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  
  .inventory-selection-search-input {
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
    padding: 10px;
  }
  
  .inventory-selection-search-input:focus {
    border-color: #007bff;
  }
  
  .inventory-selection-ship-by {
    font-size: 0.8em;
    color: #666;
    display: block;
    margin-top: 2px;
  }
  
  .inventory-selection-disabled-message {
    font-size: 0.8em;
    color: #DE3163;
    display: block;
    margin-top: 2px;
  }
  
  .inventory-selection-quantity-btn:disabled,
  .inventory-selection-quantity-input:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  