/* AdminMenu.css */
.admin-menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .admin-menu-container h1 {
    margin-bottom: 20px;
  }
  
  .admin-menu-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .admin-menu-buttons button {
    width: 200px;
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }

  .admin-menu-buttons i {
    margin-right: 10px;
  }
  
  .admin-menu-buttons button:hover {
    background-color: #0056b3;
  }  