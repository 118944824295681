.modal-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 85%;
  }
  
  .modal-popup-content p {
    margin: 0 0 15px 0;
    font-size: 1.1em;
  }
  
  .modal-popup-content.success {
    border: 2px solid #4caf50;
  }
  
  .modal-popup-content.error {
    border: 2px solid #f44336;
  }

  .modal-popup-details {
    margin: 10px 0;
    padding: 0;
    list-style-type: none;
  }
  
  .modal-popup-details li {
    margin: 5px 0;
  }
  
  .modal-popup-actions {
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }
  
  .modal-popup-confirm-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 40%;
  }
  
  .modal-popup-confirm-button:hover {
    background-color: #45a049;
  }
  
  .modal-popup-close-button {
    background-color: #f44336;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 40%;
  }
  
  .modal-popup-close-button:hover {
    background-color: #da190b;
  }
  