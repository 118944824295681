/* PrizeSelectionModal.css */

.prize-selection-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .prize-selection-modal-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 85%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
    overflow-y: auto;
    height: 80vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .prize-selection-modal-content h4 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0px;
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
  }
  
  .prize-selection-products-container {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 10px;
    width: 100%;
    margin-top: 10px;
  }
  
  .prize-selection-product-section {
    margin-bottom: 20px;
    text-align: center; /* Center align content within each product section */
    width: 100%;
  }
  
  .prize-selection-product-section h5 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #444;
    text-align: center; /* Center the product name */
  }
  
  .prize-selection-prizes-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center; /* Center the prize cards */
    width: 100%;
  }
  
  .prize-selection-prize-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    text-align: center;
    font-size: 0.9em;
    color: #555;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .prize-selection-prize-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .prize-selection-prize-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .prize-selection-quantity-controls {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .prize-selection-quantity-controls button {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 0.9em;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .prize-selection-quantity-controls button:hover {
    background-color: #0056b3;
  }
  
  .prize-selection-quantity-controls span {
    width: 30px;
    text-align: center;
    margin: 0 8px;
    font-weight: bold;
    color: #333;
  }
  
  .prize-selection-modal-actions {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 5px;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    z-index: 1000;
  }
  
  .prize-selection-modal-actions button {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .prize-selection-modal-actions button:hover {
    background-color: #0056b3;
  }
  
  .prize-selection-modal-actions button:first-child {
    background-color: #6c757d;
  }
  
  .prize-selection-modal-actions button:first-child:hover {
    background-color: #5a6268;
  }
  
  /* Animation for modal */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }  
  
  .prize-selection-search {
    margin: 0px auto;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .prize-selection-search .search-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .prize-selection-search .search-input:focus {
    border-color: #007bff;
  }  