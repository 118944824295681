.admin-products-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
}

.admin-products-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-products-header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-products-back-to-menu-button {
  padding: 8px 16px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  color: #212529;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.admin-products-back-to-menu-button:hover {
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.admin-products-back-to-menu-button i {
  font-size: 12px;
}

.admin-products-header h1 {
  font-size: 2rem;
  color: #333;
  margin: 0;
}

.admin-products-add-product-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s;
}

.admin-products-add-product-button:hover {
  background-color: #45a049;
}

.admin-products-controls {
  margin-bottom: 2rem;
}

.admin-products-search-box {
  position: relative;
  max-width: 300px;
}

.admin-products-search-box input {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

.admin-products-search-box i {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.admin-products-table-container {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
}

.admin-products-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 650px;
}

.admin-products-table th,
.admin-products-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.admin-products-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #333;
}

.admin-products-product-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.admin-products-status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
}

.admin-products-status-badge.in-stock {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.admin-products-status-badge.out-of-stock {
  background-color: #ffebee;
  color: #c62828;
}

.admin-products-action-button {
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #666;
  border-radius: 4px;
  transition: all 0.2s;
}

.admin-products-action-button:hover {
  background-color: #f0f0f0;
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-products-container {
    padding: 1rem;
    overflow-x: hidden;
  }

  .admin-products-table-container {
    margin: 0 -1rem;
    padding: 0 1rem;
    border-radius: 0;
  }

  .admin-products-table {
    display: block;
    overflow-x: auto;
  }
}

.admin-products-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding: 20px 0;
}

.admin-products-pagination-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.admin-products-pagination-button:hover:not(:disabled) {
  background-color: #e9ecef;
}

.admin-products-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.admin-products-pagination-info {
  font-size: 14px;
  color: #6c757d;
}

.admin-products-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
} 