.admin-selfcollect-container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.admin-selfcollect-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-selfcollect-header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-selfcollect-back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.admin-selfcollect-back-button:hover {
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.admin-selfcollect-search {
  margin-bottom: 20px;
}

.admin-selfcollect-search-container {
  position: relative;
  max-width: 400px;
}

.admin-selfcollect-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

.admin-selfcollect-search-input {
  width: 100%;
  padding: 10px 40px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 14px;
}

.admin-selfcollect-search-input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.admin-selfcollect-clear-search-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  padding: 0;
}

.admin-selfcollect-clear-search-button:hover {
  color: #495057;
}

.admin-selfcollect-table-container {
  overflow-x: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.admin-selfcollect-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 1000px;
}

.admin-selfcollect-table th,
.admin-selfcollect-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.admin-selfcollect-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
}

.admin-selfcollect-table tr:hover {
  background-color: #f8f9fa;
}

.admin-selfcollect-status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

.admin-selfcollect-status-badge.pending {
  background-color: #fff3cd;
  color: #856404;
}

.admin-selfcollect-status-badge.completed {
  background-color: #d4edda;
  color: #155724;
}

.admin-selfcollect-status-badge.cancelled {
  background-color: #f8d7da;
  color: #721c24;
}

.admin-selfcollect-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding: 20px 0;
}

.admin-selfcollect-pagination-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.admin-selfcollect-pagination-button:hover:not(:disabled) {
  background-color: #e9ecef;
}

.admin-selfcollect-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.admin-selfcollect-pagination-info {
  font-size: 14px;
  color: #6c757d;
}

.admin-selfcollect-loading,
.admin-selfcollect-error {
  text-align: center;
  padding: 40px;
  font-size: 1.2em;
  color: #6c757d;
}

.admin-selfcollect-error {
  color: #dc3545;
}

.admin-selfcollect-status-select {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  border: 1px solid #dee2e6;
  cursor: pointer;
  background-color: white;
  min-width: 120px;
}

.admin-selfcollect-status-select.pending {
  color: #856404;
  border-color: #ffeeba;
}

.admin-selfcollect-status-select.completed {
  color: #155724;
  border-color: #c3e6cb;
}

.admin-selfcollect-status-select.cancelled {
  color: #721c24;
  border-color: #f5c6cb;
}

.admin-selfcollect-status-select:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.admin-selfcollect-status-loading {
  margin-left: 8px;
  color: #6c757d;
}

/* Hover styles for the select */
.admin-selfcollect-status-select:not(:disabled):hover {
  border-color: #80bdff;
}

/* Focus styles for the select */
.admin-selfcollect-status-select:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.admin-selfcollect-controls {
  margin-bottom: 20px;
}

.admin-selfcollect-filters {
  margin-top: 15px;
}

.admin-selfcollect-filter-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.admin-selfcollect-filter-group label {
  font-size: 14px;
  color: #495057;
}

.admin-selfcollect-filter-select {
  padding: 6px 12px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  color: #495057;
  cursor: pointer;
  min-width: 120px;
}

.admin-selfcollect-filter-select:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.admin-selfcollect-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.admin-selfcollect-modal {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.admin-selfcollect-modal h3 {
  margin-top: 0;
  color: #333;
}

.admin-selfcollect-modal-warning {
  color: #dc3545;
  font-weight: bold;
  margin: 1rem 0;
}

.admin-selfcollect-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.admin-selfcollect-modal-confirm {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.admin-selfcollect-modal-cancel {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.admin-selfcollect-modal-confirm:hover {
  background-color: #c82333;
}

.admin-selfcollect-modal-cancel:hover {
  background-color: #5a6268;
}

/* Add these styles */
.admin-selfcollect-status-select.completed,
.admin-selfcollect-status-select.cancelled {
  opacity: 0.7;
  cursor: not-allowed;
}

.admin-selfcollect-status-select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

/* Update modal styles for different status types */
.admin-selfcollect-modal-confirm.completed {
  background-color: #28a745;
}

.admin-selfcollect-modal-confirm.cancelled {
  background-color: #dc3545;
}

.admin-selfcollect-modal-confirm.pending {
  background-color: #ffc107;
  color: #000;
}

.admin-selfcollect-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
}