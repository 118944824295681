.orders-title {
    font-family: 'Bungee';
    text-align: center;
    margin-bottom: 0px;
}

.orders-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.order-card {
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 86%;
    margin-bottom: 15px;
    padding: 15px;
}

.order-top {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: center;
    align-items: center;
}

.order-shipped {
    font-size: 0.8em;
    color: #25AF19;
}

.order-not-shipped {
    font-size: 0.85em;
    color: #DE3163;
    background: transparent;
    text-decoration: underline;
    border: none;
    cursor: pointer;
    padding: 0px;
}

.order-id {
    color: #3b3b3b;
    font-size: 0.8em;
}

.order-date {
    color: #3b3b3b;
    font-size: 0.8em;
    text-align: right;
}

.order-card img {
    width: 100px; /* Adjust as needed */
}

.order-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.order-details {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-top: -10px;
}

.product-name {
    text-align: left;
    font-size: 0.9em;
}

.ticket-cost {
    margin-top: 25px;
    font-size: 0.9em;
    display: flex;
}

.ticket-calculation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.number-of-tickets {
    margin-top: 25px;
    font-size: 0.9em;
}

.order-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
}

.selected-prizes {
    text-align: left;
    width: 90%;
}

.no-orders-message {
    margin-top: 50px;
    padding: 20px;
    background: white;
    border-radius: 10px;
}

.order-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px;
}

.orders-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
}

.batch-ship-button, .batch-ship-confirm-button {
    background: #25AF19; /* The button's background color */
    color: white; /* The text color */
    font-size: 0.8em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 4px #184012; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 40%;
    font-family: 'Bungee';
    text-shadow: 0 2px rgba(0, 0, 0, 0.25);
}


.batch-cancel-button {
    background: #DE3163; /* The button's background color */
    color: white; /* The text color */
    font-size: 0.8em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 4px #800020; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 40%;
    font-family: 'Bungee';
    text-shadow: 0 2px rgba(0, 0, 0, 0.25);
}

.order-token-icon {
    max-width: 20px;
    margin-left: 3px;
    max-height: 20px;
}

.order-page-total-cost {
    display: flex;
}

.item-limit-popup {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    z-index: 9999;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.item-limit-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 70%;
    text-align: center;
}
  
.item-limit-popup p {
    font-size: 16px;
    margin-bottom: 10px;
}
  
.item-limit-popup button {
    background: #DE3163;
    color: white;
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-shadow: 0 3px rgba(0, 0, 0, 0.25);
    font-family: 'Bungee', sans-serif;
    box-shadow: 0 4px #800020;
    transition: all 0.3s ease;
    font-size: 0.8em;
    margin-top: 15px;
}

.order-search-input {
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    align-items: center;
    justify-content: center;
    display: flex;
}

.orders-search {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.orders-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0;
  padding: 10px;
}

.orders-pagination-button {
  background: transparent;
  color: black;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  font-family: 'Bungee';
  text-shadow: 0 2px rgba(0, 0, 0, 0.25);
  padding: 0;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orders-pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.orders-pagination-button:hover:not(:disabled) {
  transform: scale(1.2);
}

.orders-pagination-info {
  color: black;
  font-family: 'Bungee';
  font-size: 1em;
  text-shadow: 0 2px rgba(0, 0, 0, 0.25);
}

.no-orders {
  color: white;
  font-family: 'Bungee';
  font-size: 1.2em;
  margin: 20px;
  text-shadow: 0 2px rgba(0, 0, 0, 0.25);
}