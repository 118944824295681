.admin-inventory-lookup-container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.admin-inventory-lookup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-inventory-lookup-header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-inventory-lookup-back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.admin-inventory-lookup-back-button:hover {
  background-color: #5a6268;
}

.admin-inventory-lookup-search {
  margin-bottom: 30px;
}

.admin-inventory-lookup-search-container {
  display: flex;
  gap: 10px;
  max-width: 600px;
}

.admin-inventory-lookup-search-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 16px;
}

.admin-inventory-lookup-search-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.admin-inventory-lookup-search-button:hover {
  background-color: #0056b3;
}

.admin-inventory-lookup-error-message {
  color: #dc3545;
  margin-top: 10px;
}

.admin-inventory-lookup-product-section {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.admin-inventory-lookup-items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 15px;
}

.admin-inventory-lookup-item-card {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  gap: 15px;
}

.admin-inventory-lookup-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.admin-inventory-lookup-item-details {
  flex: 1;
}

.admin-inventory-lookup-item-details h4 {
  margin: 0 0 10px 0;
  color: #212529;
}

.admin-inventory-lookup-batch-info {
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  margin-top: 10px;
}

.admin-inventory-lookup-batch-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #495057;
} 