.delivery-tabs {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.tab-section {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.tab-text {
  font-size: 1.2em;
  cursor: pointer;
  padding: 10px 15px;
  color: gray;
}

.tab-text.active {
  font-weight: bold;
  color: #0A89FF;
}

.vertical-divider {
  font-size: 1.4em;
  height: 20px;
  width: 1.5px;
  background-color: #515151;
}

.delivery-tabs::-webkit-scrollbar {
  display: none;
}

.status-pending {
  color: #f0ad4e;
}

.status-completed {
  color: #5cb85c;
}

.status-cancelled {
  color: #d9534f;
}

.shipments-container {
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.shipments-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #333;
  font-family: "BUNGEE";
}

.no-shipments-message {
  text-align: center;
  font-size: 1.2rem;
  color: #777;
}

.shipments-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.shipment-page-card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 80%;
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  position: relative;
}

.shipment-page-card h3 {
  color: #555;
  font-size: 1.2rem;
}

.shipment-page-card p {
  color: #666;
  font-size: 1rem;
  margin: 5px 0;
}

.shipment-page-card strong {
  color: #333;
}

.status-shipped {
  color: green;
  font-weight: bold;
}

.status-not-shipped {
  color: red;
  font-weight: bold;
}

  .shipment-page-id {
    font-size: 0.9rem;
  }

  .shipment-page-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    color: #777;
  }

.tracking-link {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s;
}

.tracking-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.cancel-collection-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.9em;
}

.cancel-collection-button:hover {
  background-color: #cc0000;
}

.cancel-confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cancel-confirmation-content {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  width: 90%;
  text-align: center;
}

.cancel-confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.confirm-cancel-button {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #666;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-cancel-button:hover {
  background-color: #cc0000;
}

.cancel-button:hover {
  background-color: #444;
}

.expired-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.expired-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 85%;
  text-align: center;
}

.expired-modal-buttons {
  margin-top: 20px;
}

.expired-modal-close-button {
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.expired-modal-close-button:hover {
  background-color: #c82333;
}