.admin-orders-container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.admin-orders-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-orders-header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-orders-back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.admin-orders-back-button:hover {
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.admin-orders-table-container {
  overflow-x: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.admin-orders-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 1000px;
}

.admin-orders-table th,
.admin-orders-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.admin-orders-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
}

.admin-orders-table tr:hover {
  background-color: #f8f9fa;
}

.admin-orders-lottery-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

.admin-orders-lottery-status.enabled {
  background-color: #d4edda;
  color: #155724;
}

.admin-orders-lottery-status.disabled {
  background-color: #f8f9fa;
  color: #6c757d;
}

.admin-orders-loading,
.admin-orders-error {
  text-align: center;
  padding: 40px;
  font-size: 1.2em;
  color: #6c757d;
}

.admin-orders-error {
  color: #dc3545;
}

.admin-orders-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding: 20px 0;
}

.admin-orders-pagination-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.admin-orders-pagination-button:hover:not(:disabled) {
  background-color: #e9ecef;
}

.admin-orders-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.admin-orders-pagination-info {
  font-size: 14px;
  color: #6c757d;
}

.admin-orders-search {
  margin-bottom: 20px;
}

.admin-orders-search-input-container {
  position: relative;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-orders-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

.admin-orders-search-input {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 10px;
  text-align: left;
}

.admin-orders-search-input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.admin-orders-clear-search-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  padding: 0;
}

.admin-orders-clear-search-button:hover {
  color: #495057;
}

.admin-orders-controls {
  margin-bottom: 20px;
}

.admin-orders-filters {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}

.admin-orders-filter-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.admin-orders-filter-group label {
  font-size: 14px;
  color: #495057;
}

.admin-orders-filter-select {
  padding: 6px 12px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  color: #495057;
  cursor: pointer;
}

.admin-orders-filter-select:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
} 