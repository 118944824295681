.manage-trades {
    width: 85%;
    min-height: 40vh;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .manage-trades h1 {
    text-align: center;
  }
  
  .manage-trades-card {
    background: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .manage-trades-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .manage-trades-prizes {
    display: flex; /* Change to flex layout */
    flex-wrap: nowrap; /* Prevent wrapping to a new line */
    gap: 16px; /* Space between cards */
    overflow-x: auto; /* Enable horizontal scrolling */
    padding-bottom: 10px; /* Add some padding for scrollbar spacing */
  }
  .manage-trades-prizes::-webkit-scrollbar {
    height: 8px; /* Height of horizontal scrollbar */
  }

  .manage-trades-prizes::-webkit-scrollbar-thumb {
      background: grey; /* Thumb color */
      border-radius: 4px;
  }

  .manage-trades-prizes::-webkit-scrollbar-track {
      background: #f1f1f1; /* Track color */
  }

  .manage-trades-prizes-container {
    margin-top: 0px;
  }
  
  .manage-trades-prize-card {
    flex: 0 0 auto; /* Prevent shrinking and keep size */
    width: 120px; /* Set a fixed width for cards */
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 5px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .manage-trades-details {
    display: flex;
    flex-direction: column;
  }
  
  .manage-trades-prize-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .manage-trades-view-offers-button,
  .manage-trades-cancel-button {
    margin-top: 10px;
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .manage-trades-view-offers-button {
    background: #007bff;
    color: #fff;
    width: 40%;
  }
  
  .manage-trades-cancel-button {
    background: #dc3545;
    color: #fff;
    width: 40%;
  }
  
  .manage-trades-view-offers-button:hover {
    background: #0056b3;
  }
  
  .manage-trades-cancel-button:hover {
    background: #b02a37;
  }  

  .manage-trades-prize-name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 5px;
  }
  
  .manage-trades-prize-description {
    font-size: 0.8em;
    margin-bottom: 5px;
  } 
  
  .manage-trades-prize-set-name {
    font-size: 0.7em;
  }

  .manage-trades-prize-quantity {
      font-size: 0.8em;
      margin-bottom: 5px;
  }

  .manage-trades-button-group {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }

  .cancel-trade-confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .cancel-trade-confirmation-popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out; /* Fade-in effect */
  }
  
  .cancel-trade-confirmation-popup-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .cancel-trade-confirm-button {
    background: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 40%;
  }
  
  .cancel-trade-confirm-button:hover {
    background: #c9302c;
  }
  
  .cancel-trade-cancel-button {
    background: #5bc0de;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 40%;
  }
  
  .cancel-trade-cancel-button:hover {
    background: #31b0d5;
  }

  .manage-trades-create-trade-button {
    display: block;
    background-color: #25AF19;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-family: 'Bungee', sans-serif;
    box-shadow: 0 4px #184012;
    padding: 8px 20px;
    width: 50%;
}

.manage-trades-header-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.manage-trades-history-button {
  background-color: #4A90E2;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease;
  font-family: 'Bungee', sans-serif;
  box-shadow: 0 4px #2A5A9C;
  width: 50%;
}

.manage-trades-history-button:hover {
  background-color: #357ABD;
}

.manage-trades-ship-by {
  display: block;
  font-size: 12px;
  color: #666;
  margin-top: 0px;
  margin-bottom: 5px;
  font-style: italic;
}

/* If the spinner needs specific positioning in the popup */
.cancel-trade-confirmation-popup-buttons .loading-spinner {
  margin: 1rem auto;
}
  