/* NotificationsDropdown.css */
.notifications-dropdown {
    position: relative;
  }
  
  .notifications-icon {
    position: relative;
    cursor: pointer;
  }
  
  .unread-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background: red;
    color: white;
    border-radius: 50%;
    padding: 4px 6px;
    font-size: 12px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 30px;
    left: 0;
    width: 250px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
  }
  
  .dropdown-menu h4 {
    margin: 0 0 10px;
  }
  
  .notification-item {
    position: relative;
    padding: 7px 25px 7px 7px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word; /* Ensure long words break properly */
    overflow-wrap: break-word; /* Ensure proper wrapping in modern browsers */
  }

  .notification-item .remove-notification {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: red;
    font-size: 20px;
    cursor: pointer;
    padding: 0 5px;
  }
  
  .notification-item .remove-notification:hover {
    color: darkred;
  }
  
  .notification-item:last-child {
    border-bottom: none;
  }
  
  .notification-item.read {
    opacity: 0.6;
  }
  
  .notification-item p {
    margin: 0;
  }
  
  .notification-item small {
    color: gray;
  }
  
  .no-notifications {
    text-align: center;
    color: gray;
  }  