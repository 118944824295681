.admin-product-view {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.admin-product-back-button {
  padding: 0.5rem 1rem;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.admin-product-main-info {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.admin-product-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.admin-product-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.admin-product-info-item {
  display: flex;
  flex-direction: column;
}

.admin-product-info-item label {
  font-weight: 600;
  color: #666;
}

.admin-product-features {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 2rem;
}

.admin-product-features-grid {
  display: grid;
  margin-top: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.admin-product-feature-item {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.admin-product-feature-enabled {
  color: #4CAF50;
  font-weight: 600;
}

.admin-product-feature-disabled {
  color: #666;
}

.admin-product-prizes {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.admin-product-prizes-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 1rem;
}

.admin-product-prize-card {
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
}

.admin-product-prize-image {
  width: 100%;
  object-fit: contain;
}

.admin-product-prize-info {
  padding: 1rem;
}

.admin-product-prize-info h3 {
  margin: 0 0 0.5rem 0;
}

.admin-product-prize-quantities {
  margin-top: 0.5rem;
}

.admin-product-prize-quantity-progress-bar {
  height: 4px;
  background-color: #e9ecef;
  border-radius: 2px;
  margin-top: 0.25rem;
  overflow: hidden;
}

.admin-product-prize-quantity-progress {
  height: 100%;
  transition: width 0.3s ease;
  background-color: #28a745;
}

.admin-product-prize-quantity {
  font-size: 0.9rem;
  color: #666;
  display: block;
}

@media (max-width: 768px) {
  .admin-product-main-info {
    grid-template-columns: 1fr;
  }
  
  .admin-product-image {
    max-width: 300px;
    margin: 0 auto;
  }
} 

.admin-product-promotion-details p {
  margin: 0;
}

.admin-product-lottery-details {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.admin-product-lottery-details span {
  margin: 0;
}

.admin-product-lottery-orders-section {
  margin-top: 1rem;
}

.admin-product-lottery-orders-toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  padding: 0.5rem;
  color: #2196F3;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s;
}

.admin-product-lottery-orders-toggle:hover {
  color: #1976D2;
}

.admin-product-lottery-orders-toggle i {
  font-size: 0.75rem;
}

.admin-product-lottery-orders-list {
  margin-top: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 4px;
}

.admin-product-lottery-order-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  background: white;
}

.admin-product-lottery-order-item:last-child {
  border-bottom: none;
}

.admin-product-lottery-order-item span {
  color: #666;
  font-size: 0.9rem;
}

/* Add smooth scrollbar for the orders list */
.admin-product-lottery-orders-list::-webkit-scrollbar {
  width: 6px;
}

.admin-product-lottery-orders-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.admin-product-lottery-orders-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.admin-product-lottery-orders-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Add responsive handling for smaller screens */
@media (max-width: 1024px) {
  .admin-product-prizes-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .admin-product-prizes-grid {
    grid-template-columns: 1fr;
  }
}

.admin-product-editable-field {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}

.admin-product-editable-field span {
  flex-grow: 1;
  padding: 6px 0;
}

.admin-product-editable-field input,
.admin-product-editable-field select {
  flex-grow: 1;
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.admin-product-editable-field input:focus,
.admin-product-editable-field select:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
}

.admin-product-editable-field button {
  background: none;
  border: none;
  padding: 6px;
  cursor: pointer;
  color: #666;
  border-radius: 4px;
  transition: all 0.2s;
}

.admin-product-editable-field button:hover {
  background-color: #f0f0f0;
  color: #2196F3;
}

.admin-product-editable-field button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.admin-product-editable-field button i {
  font-size: 14px;
}

/* Lottery specific styles */
.admin-product-lottery-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
}

.admin-product-lottery-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.admin-product-lottery-field label {
  font-size: 12px;
  color: #666;
}

/* Feature item styles */
.admin-product-feature-item {
  background: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
}

.admin-product-feature-item > label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.admin-product-feature-enabled {
  color: #28a745;
}

.admin-product-feature-disabled {
  color: #6c757d;
  font-style: italic;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .admin-product-editable-field {
    flex-direction: column;
    align-items: stretch;
  }

  .admin-product-editable-field button {
    align-self: flex-end;
  }
}

.admin-product-header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.admin-product-save-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.admin-product-save-button:hover {
  background-color: #1976D2;
}

.admin-product-save-button:disabled {
  background-color: #90CAF9;
  cursor: not-allowed;
}

.admin-product-save-button i {
  font-size: 14px;
}

.admin-product-lottery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-product-lottery-header h3 {
  font-size: 1em;
}

.admin-product-lottery-reset-button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
}

.admin-product-lottery-reset-button:hover {
  background-color: #c82333;
}

.admin-product-lottery-reset-button i {
  font-size: 12px;
}

.admin-product-reload-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.admin-product-reload-button:hover {
  background-color: #218838;
}

.admin-product-reload-button i {
  font-size: 14px;
}

.admin-product-preorder-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 16px;
  background-color: #7A85F4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.admin-product-preorder-button:hover {
  background-color: #4B4B9E;
}

.admin-preorder-to-instock-confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.admin-preorder-to-instock-confirmation-popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.admin-preorder-to-instock-confirmation-popup-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.admin-preorder-to-instock-confirmation-popup-buttons .loading-spinner {
  margin: 1rem auto;
}

.admin-preorder-to-instock-confirmation-popup-buttons button {
  padding: 8px 20px;
  border-radius: 20px;
  border: none;
  font-family: 'Bungee';
  cursor: pointer;
  transition: all 0.3s ease;
}

.admin-preorder-to-instock-confirmation-popup-cancel-button {
  background: #DE3163;
  color: white;
  box-shadow: 0 4px #800020;
  width: 40%;
}

.admin-preorder-to-instock-confirmation-popup-confirm-button {
  background: #7A85F4;
  color: white;
  box-shadow: 0 4px #4B4B9E;
  width: 40%;
}