.image-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 1000;
}

.image-popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out; /* Fade-in effect */
}

.image-popup-close {
    margin-bottom: 20px;
}

.full-size-image {
    max-width: 100%;
    max-height: 100%;
}

.image-card {
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-bottom: 10px;
    padding: 10px;
}

@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}

.image-popup.closing .image-popup-content {
    animation: fadeOut 0.3s ease-in-out; /* Fade-out effect for content */
}

.image-popup.closing {
    animation: fadeOut 0.3s ease-in-out; /* Fade-out effect */
}