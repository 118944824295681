.trading-marketplace {
    width: 85%;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-bottom: 20px;
}
  
h1 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0px;
}

.trading-marketplace-profile-picture {
    width: 40px; /* Set the desired width */
    height: 40px; /* Set the desired height */
    border-radius: 50%; /* Makes it a perfect circle */
    object-fit: cover; /* Ensures the image scales correctly */
    border: 2px solid #ddd; /* Optional: Add a border for styling */
    margin-right: 10px; /* Optional: Add some spacing */
  }
  
.trading-marketplace-create-trade-button:hover {
    background: #0056b3;
}
  
.trading-marketplace-trade-card {
    margin-bottom: 20px;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
  
.trading-marketplace-trade-header {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}
  
.trading-marketplace-trade-created-by {
    font-weight: bold;
    color: #333;
}
  
.trading-marketplace-trade-details {
    display: flex;
    flex-direction: column;
}
  
.trading-marketplace-prizes-section {
    width: 45%;
}

.trading-marketplace-prizes-section h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #007bff;
}
  
.trading-marketplace-prizes-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
.trading-marketplace-prizes-section li {
    margin-bottom: 10px;
    font-size: 1em;
}
  
.trading-marketplace-ship-by-date {
    display: block;
    margin-top: 5px;
    font-size: 0.9em;
    color: #888;
}
  
.trading-marketplace-arrow {
    display: flex;
    align-items: center;
    font-size: 2em;
    color: #007bff;
}
  
.trading-marketplace-offer-trade-button, .trading-marketplace-view-offer-button {
    display: block;
    margin: 10px 0 0 auto;
    padding: 8px 12px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease;
}
  
.trading-marketplace-offer-trade-button:hover, .trading-marketplace-view-offer-button:hover {
    background: #0056b3;
}
  
.trading-marketplace-no-trades {
    text-align: center;
    font-size: 1.2em;
    color: #555;
}

.trading-marketplace-prizes-container {
    margin-top: 0px;
}
  
.trading-marketplace-prizes {
    display: flex; /* Change to flex layout */
    flex-wrap: nowrap; /* Prevent wrapping to a new line */
    gap: 16px; /* Space between cards */
    overflow-x: auto; /* Enable horizontal scrolling */
    padding-bottom: 10px; /* Add some padding for scrollbar spacing */
}


.trading-marketplace-prizes::-webkit-scrollbar {
    height: 8px; /* Height of horizontal scrollbar */
}

.trading-marketplace-prizes::-webkit-scrollbar-thumb {
    background: grey; /* Thumb color */
    border-radius: 4px;
}

.trading-marketplace-prizes::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
}
  
.trading-marketplace-prize-card {
    flex: 0 0 auto; /* Prevent shrinking and keep size */
    width: 120px; /* Set a fixed width for cards */
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 5px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.trading-marketplace-prize-name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 5px;
}
  
.trading-marketplace-prize-image {
    width: 60%;
    object-fit: cover;
    margin-bottom: 8px;
    border-radius: 5px;
}
  
.trading-marketplace-ship-by,
.trading-marketplace-arrival {
  display: block;
  font-size: 12px;
  color: #666;
  margin-top: 0px;
  margin-bottom: 5px;
  font-style: italic;
  color: #ff7e5f;
}

.trading-marketplace-prize-description {
    font-size: 0.8em;
    margin-bottom: 5px;
}

.trading-marketplace-prize-set-name {
    font-size: 0.7em;
}

.trading-marketplace-prize-quantity {
    font-size: 0.8em;
    margin-bottom: 5px;
}

.trading-marketplace-search {
  margin: 20px auto;
  max-width: 600px;
  position: relative; /* For positioning the spinner */
  display: flex;
  justify-content: center;
}

.trading-marketplace-search-input {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid #ddd;
  border-radius: 10px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.trading-marketplace-search-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0,123,255,0.2);
}

.trading-marketplace-search-input::placeholder {
  color: #999;
}

.trading-marketplace-search-spinner {
  position: absolute;
  right: 35px; /* Adjust based on your padding */
  top: 50%;
  transform: translateY(-50%);
}

.trading-marketplace-search-spinner .loading-spinner {
  width: 20px; /* Smaller spinner for the search */
  height: 20px;
}

.trading-marketplace-prizes-container h3 {
  text-align: left;
  margin-bottom: 10px;
}