.admin-add-product-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    padding: 30px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.admin-add-product-form h2 {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.8em;
}

.admin-add-product-form h3 {
    color: #444;
    margin: 20px 0;
    padding-bottom: 10px;
    border-bottom: 2px solid #eee;
}

.admin-add-product-form form > div {
    margin-bottom: 15px;
}

.admin-add-product-form label {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-weight: 500;
}

.admin-add-product-form input[type="text"],
.admin-add-product-form input[type="number"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1em;
    transition: border-color 0.3s ease;
}

.admin-add-product-form input[type="text"]:focus,
.admin-add-product-form input[type="number"]:focus {
    outline: none;
    border-color: #0A89FF;
    box-shadow: 0 0 0 2px rgba(10, 137, 255, 0.1);
}

.admin-add-product-form input[type="file"] {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
}

.admin-add-product-form input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.2);
}

.prize-field {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px solid #eee;
}

.prize-field input,
.prize-field select {
    margin-bottom: 10px;
    width: 100%;
}

.prize-field select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: white;
    font-size: 1em;
    cursor: pointer;
}

.prize-field select:focus {
    outline: none;
    border-color: #0A89FF;
    box-shadow: 0 0 0 2px rgba(10, 137, 255, 0.1);
}

/* Buttons styling */
.admin-add-product-form button {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
}

.admin-add-product-form button[type="submit"] {
    background-color: green;
    color: white;
    width: 100%;
    margin-top: 20px;
    font-size: 1.1em;
}

.admin-add-product-form button[type="submit"]:hover {
    background-color: darkgreen;
}

/* Style for Delete Prize button */
.prize-field button {
    background-color: #dc3545;
    color: white;
    margin-top: 10px;
}

.prize-field button:hover {
    background-color: #c82333;
}

/* Style for Add Another Prize button */
button[type="button"]:not(.prize-field button) {
    background-color: #0A89FF;
    color: white;
    margin-top: 10px;
}

button[type="button"]:not(.prize-field button):hover {
    background-color: #0066cc;
}

/* Success/Error message styling */
.admin-add-product-form p {
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    border-radius: 8px;
    background-color: #d4edda;
    color: #155724;
}

/* Responsive design */
@media (max-width: 768px) {
    .admin-add-product-form {
        padding: 20px;
        margin: 10px;
    }
}