.results-page-popup {
    top: 0;
    left: 0;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align content to the top */
    z-index: 10;
    padding: 50px 0;
    box-sizing: border-box; /* Make sure padding doesn't affect the overall size */
    margin-bottom: 50px;
    overflow-y: auto; /* Enable scrolling */
    padding: 30px;
    border-radius: 15px;
    max-width: 90%;
    width: 1200px;
    margin: 20px auto;
}

.results-page-text {
    font-weight: bold;
    color: white;
    text-shadow:
    3px 3px 0 #000,
    -3px 3px 0 #000,
    -3px -3px 0 #000,
    3px -3px 0 #000;
    font-size: 2.5em;
    margin-bottom: 20px;
    margin-top: 0;
}

.results-page-prize-text {
    position: absolute;
    top: 53%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position precisely */
    color: white;
    font-weight: bold;
    font-family: 'Bungee';
    font-size: 1.5em;
    /* Add more styles if needed */
}

.results-page-try-button {
    background: #25AF19; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.5em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 6px #184012; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 80%;
    font-family: 'Bungee';
    text-shadow: 0 4px rgba(0, 0, 0, 0.25);
}

.results-page-browse-button {
    background: #7A85F4; /* The button's background color */
    color: white; /* The text color */
    font-size: 1.3em; /* The size of the text */
    border: none; /* Remove the border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 0px;
    margin: 15px 0; /* Margin above and below the button */
    box-shadow: 0 6px #4B4B9E; /* Shadow effect to make the button look clickable */
    text-transform: uppercase; /* UPPERCASE text */
    cursor: pointer; /* Pointer cursor on hover */
    width: 80%;
    font-family: 'Bungee';
    text-shadow: 0 4px rgba(0, 0, 0, 0.25);
}

.results-page-prize-card {
  background: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.3);
  transition: transform 0.2s;
  width: 100%;
  margin-bottom: 20px;
}

.results-page-prize-card:hover {
  transform: translateY(-5px);
}

.results-page-prize-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
}

.results-page-prize-image:hover {
  transform: scale(1.05);
}

.results-page-prize-name {
  font-size: 1.2em;
  font-weight: bold;
  margin: 5px 0;
  color: #333;
}

.results-page-prize-description {
  font-size: 0.9em;
  margin-bottom: 5px;
}

.results-page-prize-rank {
  display: inline-block;
  padding: 5px 10px;
  background: #ffeb3b;
  border-radius: 15px;
  font-weight: bold;
  color: #333;
}

.results-page-prize-quantity {
  font-size: 0.9em;
  margin-bottom: 10px;
  margin-top: 0px;
}

.results-page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}